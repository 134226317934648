/**
 * Typowall Class
 *
 * Creates a typographic wall effect by repeating the text of an element across multiple rows.
 * This class is designed to facilitate the dynamic creation of visually engaging text patterns,
 * where a single word or short phrase is duplicated to fill the designated area.
 *
 * Options:
 * - initAttr: Specifies the data attribute name for initializing Typowall on an element. Defaults to 'data-typowall'.
 * - typoRowValue: Prefix for data attribute values assigned to each row for identification and potential styling. Defaults to 'row'.
 * - rowCount: Number of rows to generate for the typographic wall. Defaults to 3.
 * - wordCount: Number of times the text should be repeated within each row. Defaults to 10.
 *
 * Example usage:
 * const myTypowall = new Typowall(document.querySelector('.my-typowall'), { wordCount: 15 });
 * myTypowall.initialize();
 */

class Typowall {
    constructor (element, options) {
        // Merges default options with any user-provided options.
        const defaults = {
            initAttr: 'data-typowall', // The data attribute to target the typographic wall.
            typoRowValue: 'row', // Prefix for data attributes to identify row numbers.
            rowCount: 3, // The default number of rows to generate.
            wordCount: 30 // The default number of times the text repeats per row.
        };

        // Stores the combined default and provided options.
        this.settings = Object.assign({}, defaults, options);
        this.text = null; // Placeholder for the initial text content of the typowall.

        // The DOM element where the typowall effect is applied.
        this.$typowall = element;
    }

    async initialize (callback) {
        // Constructs the typographic wall.
        this.buildTypoWall();

        // Executes the provided callback function, if any, after the wall is built.
        if (callback && typeof callback === 'function') {
            callback();
        }
    }

    buildTypoWall () {
        // Only retrieves and clears the original text content if it hasn't been done already.
        if (this.text === null) {
            this.text = this.$typowall.textContent; // Stores the original text content.
            this.$typowall.textContent = ''; // Clears the existing content of the element.
        }

        // Generates the specified number of rows, each containing the repeated text.
        for (let i = 0; i < this.settings.rowCount; i++) {
            const p = document.createElement('p'); // Creates a new paragraph element for each row.

            let content = '';
            for (let j = 0; j < this.settings.wordCount; j++) {
                content += this.text + ' '; // Repeats the text the specified number of times.
            }

            p.textContent = content; // Sets the repeated text as the content of the paragraph.

            // Sets a data attribute to identify the row, using the provided row value prefix.
            p.setAttribute(this.settings.initAttr, `${this.settings.typoRowValue}-${i + 1}`);
            this.$typowall.appendChild(p); // Appends the paragraph to the typowall element.
        }
    }
}

export { Typowall };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-typowall]');
        if ($$roots.length > 0) {
            $$roots.forEach(($root, index) => {
                const rowCount = $root.dataset.typowall;
                const options = {
                    index
                };

                if (!isNaN(rowCount) && rowCount !== '') {
                    options.rowCount = rowCount;
                }

                const $rootAPI = new Typowall($root, options);

                $rootAPI.initialize();
            });
        }
    }
});
